import { createSlice } from "@reduxjs/toolkit";

const globalSlice = createSlice({
  name: "globalSlice",
  initialState: {
    isMobile:false,
    template:null
  },
  reducers: {
    setWindowQuery(state, action) {
    state.isMobile=action.payload;
    },
    setTemplate(state,action){
      state.template=action.payload
    }
  
  },
});

export default globalSlice.reducer;
export const { setWindowQuery,setTemplate } = globalSlice.actions;
