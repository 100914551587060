import React, { useState, useEffect } from 'react';
import {useLocation,useNavigate} from 'react-router-dom';
import { Box, Typography, Card ,Radio,FormControlLabel} from '@mui/material';
import Header from "../Components/Header";
import { useDispatch } from 'react-redux';
import { setTemplate } from '../store/slices/globalSlice';
const chunkArray = (array, chunkSize) => {
  const results = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    results.push(array.slice(i, i + chunkSize));
  }
  return results;
};

const TemplateList = () => {
  // const [templateList,setTemplateList] = useState([
  //   { id: "1", order: [{ id: 1, title: 'VARC',index:0 }, { id: 1, title: 'LRDI',index:1 }, { id: 1, title: 'QUANTS',index:2 }] },
  //   { id: "2", order: [{ id: 1, title: 'LRDI',index:1 }, { id: 2, title: 'VARC',index:0 }, { id: 3, title: 'QUANTS',index:2 }] },
  //   { id: "3", order: [{ id: 1, title: 'VARC',index:0 }, { id: 2, title: 'QUANTS' ,index:2}, { id: 3, title: 'LRDI',index:1 }] }]);
  const [templateList,setTemplateList] = useState([]);
  const [selectedTemplate,setSelectedTemplate] = useState(null);
  const [selectedOrder,setSelectedOrder] = useState(null);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(()=>{
    if(state?.sectionOrderOptions)
    {setTemplateList(state?.sectionOrderOptions);}
    console.log("24",templateList);
  },[state])
  const chunks = chunkArray(templateList, 3);
  const handleRadioChange = (event) => {
    setSelectedTemplate(event.target.value);
    console.log("32",event.target.value);
    setSelectedOrder(templateList[event.target.value-1]?.order.map(itm=>{return itm.index}));
    
  };
  const handleTemplateClick = (event) => {
     dispatch(setTemplate(selectedOrder));
     navigate("/instructions", {
      state: {
        name: state.name,
        email: state.email,
        uid: state.uid,
        mockId: state.mockId,
        setId: state.setId,
        mockType:state.mockType,
        title:state.mockTitle
      },
    });
  };
  return (
    <div>
        <Header title={state.mockTitle}/>
      <div className='container' style={{paddingLeft: "10%",
            paddingRight: "10%",
            marginTop: 10,height:"80%"}}><h3>Section Ordering</h3><br></br>
      <h4>Select the order in which the exam section are to be administered.</h4><br></br>
      <h5>Once you select your section order, you mush view ALL questions in each section, in the order you have selected, before moving onto the next section. You will NOT be able to return to this screen.</h5><br></br>
      {chunks.map((chunk, chunkIndex) => (
        <Box
          key={chunkIndex}
          sx={{
            display: "flex",
            flexDirection: "row",
            //justifyContent: "center",
            alignItems: "center",
            gap: 5,
            paddingLeft: 4,
            paddingRight: 4,
            marginTop: 1,
            
          }}
        >
  <Box sx={{ display: 'flex', flexDirection: 'row' ,gap:3 }}>
    {chunk.map((item, index) => (
      <Box
        key={index}
        sx={{
          //flexBasis: '30%',
          height: '13em',
          paddingBottom: 2,
          //gap:3
        }}
      >
        <Card
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 4,
            position: 'relative', // This is to position the radio button absolutely within the card
            //justifyItems:'center',
            //alignItems:'center'
          }}
        >
          <Box
            sx={{
              backgroundColor: '#0057C5',
              minHeight: '3em',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width:'20rem'
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 500,
                textAlign: 'center',
                color: 'white',
              }}
            >
              {`Template ${chunkIndex * 3 + index + 1}`}
            </Typography>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            {item.order.map((itm, itmIndex) => (
              <Typography
                key={itmIndex}
                sx={{
                  paddingBottom: 1,
                  textAlign: 'center',
                  color: '#464443',
                  fontWeight: 500,
                }}
              >
                {itm.title}
              </Typography>
            ))}
          </Box>
          <Box
            sx={{
              //position: 'absolute',
              //top: '%',
              right: '10px',
              transform: 'translateY(-50%)',
              display: 'flex',
             // alignItems: 'center',
             // justifyContent:'center'

            }}
          >
            <FormControlLabel
              control={
                <Radio
                  checked={selectedTemplate === `${chunkIndex * 3 + index + 1}`}
                  onChange={handleRadioChange}
                  value={`${chunkIndex * 3 + index + 1}`}
                />
              }
              label=""
            />
          </Box>
        </Card>
      </Box>
    ))}
  </Box>
        </Box>
      ))}
    
      </div>
      <Box sx={{ marginLeft:"90%",marginTop:"20%" }}>
              {/* <Button onClick={handleSubmit}>Start Mock</Button> */}
              <button onClick={handleTemplateClick} disabled={selectedTemplate==null?true:false}className="custom-btn btn-12">
                <span style={{ fontSize: 18, paddingTop: 4 }}>Click!</span>
                <span
                  style={{ fontSize: 18, paddingTop: 4 }}
                  className="startMock"
                >
                  Next
                </span>
              </button>
            </Box>
    </div>
  );
};

export default TemplateList;
