import request from "./Request";

// api for creating attempt id

export const get_attemptId_And_Questions = async (
  name,
  email,
  uid,
  mockId,
  setId,
  template
) => {
  const token = localStorage.getItem("auth_token");
  const jsonData = {
    name,
    email,
    template
  };
  try {
    const res = await request({
      url: `/api/student/v1/mock/post/${uid}/${setId}/${mockId}`,
      type: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        uid: uid,
      },
      data: JSON.stringify(jsonData),
    });
    // //console.log(res);
    return res;
  } catch (err) {
    console.error(err);
    //console.log("Error --> attempt id can not be created!!!");
    return err;
  }
};

// api for fetching main data

export const fetchQuestions = async (attemptId, uid) => {
  const token = localStorage.getItem("auth_token");
  try {
    const res = request({
      url: `/api/student/v1/quizs/${attemptId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        uid: uid,
        attemptId: attemptId,
      },
    });
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// api for submit student answers(section-wise)

export const submitSectionWise = async (
  mock_id,
  attempt_id,
  section_id,
  payload,
  uid
) => {
  const token = localStorage.getItem("auth_token");
  // data[type] = payload;
  try {
    const res = request({
      url: `/api/student/v1/mock/submit/${mock_id}/${attempt_id}/${section_id}`,
      type: "POST",
      data: { answers: payload },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        attemptId: attempt_id,
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    //console.log(err);
    return err;
  }
};

// api for submit student answers(TOGGLE ALLOWED)

export const submitAllSections = async (mock_id, attempt_id, payload, uid) => {
  const token = localStorage.getItem("auth_token");
  try {
    const res = request({
      url: `/api/student/v1/mock/submit/${mock_id}/${attempt_id}`,
      type: "POST",
      data: { data: payload },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        attemptId: attempt_id,
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    //console.log(err);
    return err;
  }
};

// api for college predictor

export const getPredictCollege = async (uid, payload, mockId) => {
  const token = localStorage.getItem("auth_token");
  const res = request({
    url: `/api/student/v1/analyse/read/${uid}/${mockId}`,
    type: "POST",
    data: payload,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      uid: uid,
    },
  });
  return res;
};

// api for user verification (MBR Side)

export const getVerified = async (email, otp, mockId) => {
  try {
    const res = request({
      url: `/api/student/v1/verify/user`,
      type: "POST",
      data: { email: email, otp: otp, mockId: mockId },
      headers: { "Content-Type": "application/json" },
    });
    return res;
  } catch (err) {
    //console.log(err);
    return err;
  }
};

// api for fetching Instructions

export const getInstructions = async (mockId, uid) => {
  const token = localStorage.getItem("auth_token");
  try {
    const res = request({
      url: `/api/student/v1/mock/${mockId}/instructions`,
      headers: {
        "Content-Type": "application/json",
        uid: uid,
        Authorization: "Bearer " + token,
      },
    });
    return res;
  } catch (err) {
    //console.log(err);
    return err;
  }
};

// api for fetching Terms & Conditions

export const getTermsAndConditions = async (mockId, uid) => {
  const token = localStorage.getItem("auth_token");
  try {
    const res = request({
      url: `/api/student/v1/mock/${mockId}/terms`,
      headers: {
        "Content-Type": "application/json",
        uid: uid,
        Authorization: "Bearer " + token,
      },
    });
    return res;
  } catch (err) {
    //console.log(err);
    return err;
  }
};

//api for token validation
export const validateToken = async (uid) => {
  try {
    const token = localStorage.getItem("auth_token");
    const res = request({
      url: `/api/student/v1/verify/validate`,
      type: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        uid: uid,
      },
    });
    return res;
  } catch (err) {
    //console.log(err);
    return err;
  }
};
// discard button api(Resume mock)

export const discardMock = async (attemptId, uid) => {
  const token = localStorage.getItem("auth_token");
  try {
    const res = request({
      url: `/api/student/v1/mock/discard/${attemptId}`,
      type: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        uid: uid,
        attemptId: attemptId,
      },
    });
    if (res) {
      localStorage.clear();
    }

    return res;
  } catch (err) {
    ////console.log(err);
    return err;
  }
};

// api to save student response on mock closure(Non-toggle)

export const saveStudentProgressNonToggle = async (
  mock_id,
  attempt_id,
  section_id,
  payload,
  uid,
  remainingTime
) => {
  const token = localStorage.getItem("auth_token");
  try {
    const res = request({
      url: `/api/student/v1/mock/pause/${mock_id}/${attempt_id}/${section_id}`,
      type: "POST",
      data: { answers: payload, timer: remainingTime },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        uid: uid,
        attemptId: attempt_id,
      },
    });
    return res;
  } catch (err) {
    ////console.log(err);
    return err;
  }
};

// api to save student response on mock closure(toggle)

export const saveStudentProgressToggle = async (
  mock_id,
  attempt_id,
  payload,
  uid,
  remainingTime
) => {
  const token = localStorage.getItem("auth_token");
  try {
    const res = request({
      url: `/api/student/v1/mock/pause/${mock_id}/${attempt_id}`,
      type: "POST",
      data: { data: payload, timer: remainingTime },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        uid: uid,
        attemptId: attempt_id,
      },
    });
    return res;
  } catch (err) {
    ////console.log(err);
    return err;
  }
};

// api to GET student response on mock resume

export const getStudentProgress = async (attempt_id, uid) => {
  const token = localStorage.getItem("auth_token");
  try {
    const res = request({
      url: `/api/student/v1/mock/resume/${attempt_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        uid: uid,
        attemptId: attempt_id,
      },
    });
    return res;
  } catch (err) {
    ////console.log(err);
    return err;
  }
};
