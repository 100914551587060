// import React from "react";
// import { useState, useEffect, useCallback } from "react";
// import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { submitSectionWise } from "../services/Mock_api";
// import { submitAllSections } from "../services/Mock_api";
// import Modal from "@mui/material/Modal";
// import Box from "@mui/material/Box";
// import { SubHeading } from "./../styleSheets/Style";
// import { Typography } from "@mui/material";
// import { InfinitySpin } from "react-loader-spinner";
// import { Button } from "antd";
// import { Image } from "react-bootstrap";
// import { MyButton } from "./../styleSheets/Style";
// import { encode, decode } from "base-64";
// import { useDispatch, useSelector } from "react-redux";
// import { setCurrentSectionIndex } from "../store/slices/mockDataSlice";
// import Countdown from "react-countdown";

// export const NewTimer = React.memo(({ sectionIndex, defaultMinutes }) => {
//   const buttonStyle = {
//     background: "linear-gradient(91.59deg, #FD4153 18.67%, #F77A5B 98.68%)",
//     width: "138px",
//     color: "#fff",
//     borderRadius: "20px",
//   };
//   const navigate = useNavigate();
//   const [state, setState] = useState(1);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [done, setDone] = useState(false);
//   const [err, setErr] = useState(false);
//   const COUNTER_KEY = "my-counter";
//   const dispatch = useDispatch();

//   //Accessing redux store

//   const {
//     isToggleAllowed,
//     studentResponse,
//     sections,
//     currentSectionIndex,
//     mockId,
//     attemptId,
//     uid,
//   } = useSelector((state) => state.mockData);
//   const userData = useSelector(() => state.userData);

//   const submitResponseFunc = async () => {
//     setIsLoaded(true);
//     if (isToggleAllowed) {
//       try {
//         const studentResponseArr = studentResponse.map((section) => {
//           const answers = section.questions.map((obj) => {
//             return {
//               questionId: obj.questionId._id,
//               studentAnswer: obj?.studentAnswer,
//               studentAnswerIndex: obj.studentAnswerIndex,
//               duration: obj?.duration,
//               stage: obj?.stage,
//             };
//           });
//           return {
//             section: section.section,
//             questions: answers,
//           };
//         });
//         console.log(studentResponseArr);
//         const response = await submitAllSections(
//           mockId,
//           attemptId,
//           studentResponseArr,
//           uid
//         );
//         console.log(response);
//         if (response.status == 200) {
//           setState(2);
//           window.localStorage.removeItem(COUNTER_KEY);
//         } else {
//           setState(3);
//           console.log("some error occurred");
//         }
//       } catch (err) {
//         console.log(err);
//         setState(3);
//       }
//     } else {
//       try {
//         const { questions, section } = studentResponse[currentSectionIndex];
//         const studentResponseArr = questions.map((e) => {
//           return {
//             questionId: e.questionId._id,
//             studentAnswer: e?.studentAnswer,
//             studentAnswerIndex:
//               e?.studentAnswerIndex === undefined || null
//                 ? null
//                 : e?.studentAnswerIndex,
//             duration: e?.duration,
//             stage: e?.stage,
//           };
//         });
//         const response = await submitSectionWise(
//           mockId,
//           attemptId,
//           section,
//           studentResponseArr,
//           uid
//         );
        
//         console.log("108:",response);
//         if (response.status == 200) {
//           window.localStorage.removeItem(COUNTER_KEY);
//           if (currentSectionIndex < studentResponse.length - 1) {
//             setIsLoaded(false);
//             dispatch(setCurrentSectionIndex(currentSectionIndex + 1));
//           } else {
//             setIsLoaded(true);
//             setState(2);
//           }
//         }
//       } catch (err) {
        
//         console.log("121",err);
//         setState(3);
//         //setErr(true);
//       }
//     }
//   };
//   // Renderer callback with condition
//   const renderer = ({ hours, minutes, seconds, completed }) => {
//     if (completed) {
//       // Render a completed state
//       submitResponseFunc();
//       return;
//     } else {
//       // Render a countdown
//       window.localStorage.setItem(
//         COUNTER_KEY,
//         hours * 60 * 60 + minutes * 60 + seconds
//       );
//       return (
//         <span className="Timer">
//           {hours < 10 ? `0${hours}` : hours}:
//           {minutes < 10 ? `0${minutes}` : minutes}:
//           {seconds < 10 ? `0${seconds}` : seconds}
//         </span>
//       );
//     }
//   };

//   const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: 650,
//     border: "none",
//     height: 400,
//     bgcolor: "white",
//     borderRadius: "20px ",
//     boxShadow: 10,
//     p: 0,
//     m: 0,
//   };

//   useEffect(()=>{
//    console.log(163);
//   },[localStorage.getItem(COUNTER_KEY)])

//   return (
//     <React.Fragment>
//       <span>
//         {isLoaded ? (
//           <React.Fragment>
//             <Modal
//               open={true}
//               aria-labelledby="modal-modal-title"
//               aria-describedby="modal-modal-description"
//             >
//               <Box sx={style}>
//                 {state === 1 ? (
//                   <>
//                     {" "}
//                     <div
//                       style={{ marginTop: "3em" }}
//                       className="d-flex justify-content-center"
//                     >
//                       <SubHeading className="m-4 ps-3">
//                         Submitting Section...{" "}
//                       </SubHeading>
//                     </div>
//                     <div
//                       className="d-flex justify-content-center"
//                       style={{ marginTop: "1em" }}
//                     >
//                       <div style={{ marginLeft: "12px" }}>
//                         {" "}
//                         <InfinitySpin color="blue" />
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-center mt-4 ">
//                       <Typography>Please Wait...</Typography>
//                     </div>
//                   </>
//                 ) : state === 2 ? (
//                   <>
//                     {" "}
//                     <div
//                       className="d-flex justify-content-center"
//                       style={{ height: "50%", width: "100%" }}
//                     >
//                       <div
//                         style={{
//                           height: "100%",
//                           backgroundColor: "#0075FF",
//                           width: "100%",
//                           borderTopLeftRadius: "10px ",
//                           borderTopRightRadius: "10px ",
//                         }}
//                         className="d-flex justify-content-center align-items-center"
//                       >
//                         <Image
//                           className="img-fluid text-center ps-4 "
//                           src="/Group103.png"
//                           alt="no IMage"
//                           width={300}
//                         ></Image>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-center ">
//                       <SubHeading
//                         style={{ fontWeight: "800" }}
//                         className="m-4 ps-3"
//                       >
//                         Thank You!{" "}
//                       </SubHeading>
//                     </div>
//                     <div className="d-flex justify-content-center text-muted">
//                       <Typography fontWeight={700}>
//                         Congrats! You have completed the Mock test
//                       </Typography>
//                     </div>
//                     <div
//                       className="d-flex justify-content-center"
//                       style={{ marginTop: "1em" }}
//                     >
//                       <MyButton
//                         variant="contained"
//                         sx={{
//                           ...buttonStyle,
//                           background:
//                             " linear-gradient(90.38deg, #2400FF 5.86%, #725BFF 99.82%)",
//                           borderRadius: "30px",
//                         }}
//                         onClick={() => {
//                           setDone(true);
//                           navigate(`/analysis/${mockId}/${attemptId}/overall`);
//                         }}
//                         disabled={done}
//                       >
//                         DONE
//                       </MyButton>
//                     </div>
//                   </>
//                 ) : state === 3 ? (
//                   <>
//                     <div
//                       style={{ marginTop: "3em" }}
//                       className="d-flex justify-content-center"
//                     >
//                       <SubHeading className="m-4 ps-3">
//                         Section Submitting...{" "}
//                       </SubHeading>
//                     </div>
//                     <div
//                       className="d-flex justify-content-center"
//                       style={{ marginTop: "1em" }}
//                     >
//                       <div style={{ marginLeft: "12px" }}>
//                         {" "}
//                         <SubHeading
//                           style={{ color: "red" }}
//                           className="m-4 ps-3"
//                         >
//                           Some Error Occurred!!!{" "}
//                         </SubHeading>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-center mt-4 ">
//                       <Button onClick={() => window.location.reload()}>
//                         Try Again
//                       </Button>
//                     </div>
//                   </>
//                 ) : (
//                   ""
//                 )}
//               </Box>
//             </Modal>
//           </React.Fragment>
//         ) : (
//           <>
//           {console.log(localStorage.getItem(COUNTER_KEY),"294 Timer")}
//           <Countdown
//             key={sectionIndex}
//             date={
//               Date.now() +
//               (localStorage.getItem(COUNTER_KEY) !== null &&
//               localStorage.getItem(COUNTER_KEY) !== undefined &&
//               localStorage.getItem(COUNTER_KEY)!==''
//                 ? localStorage.getItem(COUNTER_KEY) * 1000
//                 : defaultMinutes * 60 * 1000)
//             }
//             renderer={renderer}
//             autoStart={true}
//           /></>
//         )}
//       </span>
//     </React.Fragment>
//   );
// });
import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { submitSectionWise } from "../services/Mock_api";
import { submitAllSections } from "../services/Mock_api";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { SubHeading } from "./../styleSheets/Style";
import { Typography } from "@mui/material";
import { InfinitySpin } from "react-loader-spinner";
import { Button } from "antd";
import { Image } from "react-bootstrap";
import { MyButton } from "./../styleSheets/Style";
import { encode, decode } from "base-64";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentSectionIndex } from "../store/slices/mockDataSlice";
import Countdown from "react-countdown";

export const NewTimer = React.memo(({ sectionIndex }) => {
  const buttonStyle = {
    background: "linear-gradient(91.59deg, #FD4153 18.67%, #F77A5B 98.68%)",
    width: "138px",
    color: "#fff",
    borderRadius: "20px",
  };
  const navigate = useNavigate();
  const [state, setState] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [done, setDone] = useState(false);
  const [err, setErr] = useState(false);
  const COUNTER_KEY = "my-counter";
  const dispatch = useDispatch();

  //Accessing redux store

  const {
    isToggleAllowed,
    studentResponse,
    sections,
    currentSectionIndex,
    mockId,
    attemptId,
    uid,
  } = useSelector((state) => state.mockData);
  const userData = useSelector(() => state.userData);

  const submitResponseFunc = async () => {
    setIsLoaded(true);
    if (isToggleAllowed) {
      try {
        const studentResponseArr = studentResponse.map((section) => {
          const answers = section.questions.map((obj) => {
            return {
              questionId: obj.questionId._id,
              studentAnswer: obj?.studentAnswer,
              studentAnswerIndex: obj.studentAnswerIndex,
              duration: obj?.duration,
              stage: obj?.stage,
            };
          });
          return {
            section: section.section,
            questions: answers,
          };
        });
        console.log(studentResponseArr);
        const response = await submitAllSections(
          mockId,
          attemptId,
          studentResponseArr,
          uid
        );
        console.log(response);
        if (response.status == 200) {
          setState(2);
          window.localStorage.removeItem(COUNTER_KEY);
        } else {
          setState(3);
          console.log("some error occurred");
        }
      } catch (err) {
        console.log(err);
        setState(3);
      }
    } else {
      try {
        const { questions, section } = studentResponse[currentSectionIndex];
        const studentResponseArr = questions.map((e) => {
          return {
            questionId: e.questionId._id,
            studentAnswer: e?.studentAnswer,
            studentAnswerIndex:
              e?.studentAnswerIndex === undefined || null
                ? null
                : e?.studentAnswerIndex,
            duration: e?.duration,
            stage: e?.stage,
          };
        });
        const response = await submitSectionWise(
          mockId,
          attemptId,
          section,
          studentResponseArr,
          uid
        );
        console.log(response);
        if (response.status == 200) {
          window.localStorage.removeItem(COUNTER_KEY);
          if (currentSectionIndex < studentResponse.length - 1) {
            setIsLoaded(false);
            dispatch(setCurrentSectionIndex(currentSectionIndex + 1));
          } else {
            setIsLoaded(true);
            setState(2);
          }
        }
      } catch (err) {
        console.log(err);
        setState(3);
        //setErr(true);
      }
    }
  };
  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      submitResponseFunc();
      return;
    } else {
      // Render a countdown
      window.localStorage.setItem(
        COUNTER_KEY,
        hours * 60 * 60 + minutes * 60 + seconds
      );
      return (
        <span className="Timer">
          {hours < 10 ? `0${hours}` : hours}:
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </span>
      );
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    border: "none",
    height: 400,
    bgcolor: "white",
    borderRadius: "20px ",
    boxShadow: 10,
    p: 0,
    m: 0,
  };

  return (
    <React.Fragment>
      <span>
        {isLoaded ? (
          <React.Fragment>
            <Modal
              open={true}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                {state === 1 ? (
                  <>
                    {" "}
                    <div
                      style={{ marginTop: "3em" }}
                      className="d-flex justify-content-center"
                    >
                      <SubHeading className="m-4 ps-3">
                        Submitting Test...{" "}
                      </SubHeading>
                    </div>
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: "1em" }}
                    >
                      <div style={{ marginLeft: "12px" }}>
                        {" "}
                        <InfinitySpin color="blue" />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4 ">
                      <Typography>Please Wait...</Typography>
                    </div>
                  </>
                ) : state === 2 ? (
                  <>
                    {" "}
                    <div
                      className="d-flex justify-content-center"
                      style={{ height: "50%", width: "100%" }}
                    >
                      <div
                        style={{
                          height: "100%",
                          backgroundColor: "#0075FF",
                          width: "100%",
                          borderTopLeftRadius: "10px ",
                          borderTopRightRadius: "10px ",
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Image
                          className="img-fluid text-center ps-4 "
                          src="/Group103.png"
                          alt="no IMage"
                          width={300}
                        ></Image>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center ">
                      <SubHeading
                        style={{ fontWeight: "800" }}
                        className="m-4 ps-3"
                      >
                        Thank You!{" "}
                      </SubHeading>
                    </div>
                    <div className="d-flex justify-content-center text-muted">
                      <Typography fontWeight={700}>
                        Congrats! You have completed the Mock test
                      </Typography>
                    </div>
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: "1em" }}
                    >
                      <MyButton
                        variant="contained"
                        sx={{
                          ...buttonStyle,
                          background:
                            " linear-gradient(90.38deg, #2400FF 5.86%, #725BFF 99.82%)",
                          borderRadius: "30px",
                        }}
                        onClick={() => {
                          setDone(true);
                          navigate(`/analysis/${mockId}/${attemptId}/overall`);
                        }}
                        disabled={done}
                      >
                        DONE
                      </MyButton>
                    </div>
                  </>
                ) : state === 3 ? (
                  <>
                    <div
                      style={{ marginTop: "3em" }}
                      className="d-flex justify-content-center"
                    >
                      <SubHeading className="m-4 ps-3">
                        Section Submitting...{" "}
                      </SubHeading>
                    </div>
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: "1em" }}
                    >
                      <div style={{ marginLeft: "12px" }}>
                        {" "}
                        <SubHeading
                          style={{ color: "red" }}
                          className="m-4 ps-3"
                        >
                          Some Error Occurred!!!{" "}
                        </SubHeading>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4 ">
                      <Button onClick={() => window.location.reload()}>
                        Try Again
                      </Button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </Modal>
          </React.Fragment>
        ) : (
          <Countdown
            key={sectionIndex}
            date={
              Date.now() +
              (localStorage.getItem(COUNTER_KEY) !== null &&
              localStorage.getItem(COUNTER_KEY) !== undefined
                ? localStorage.getItem(COUNTER_KEY) * 1000
                : localStorage.getItem(COUNTER_KEY) * 60 * 1000)
            }
            renderer={renderer}
            autoStart={true}
          />
        )}
      </span>
    </React.Fragment>
  );
});

